var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"UserItem",attrs:{"data-cy":"UserItem"}},[_c('b-container',{staticClass:"UserItem-container",attrs:{"fluid":""}},[_c('div',{staticClass:"UserItem-toggle-select mr-3"},[_c('i',{class:("fa fa-caret-" + (_vm.expanded ? 'down' : 'right') + " mr-2 "),attrs:{"aria-role":"button","data-cy":("UserItem-" + (_vm.document.id) + "--toggle")},on:{"click":_vm.toggleCollapse}}),_c('b-form-checkbox',{attrs:{"type":"checkbox","value":"true","unchecked-value":"false","data-cy":("UserListItem-checkbox--" + (_vm.document.id)),"id":_vm.checkboxId},on:{"change":_vm.notifyCheckboxClick},model:{value:(_vm.checked),callback:function ($$v) {_vm.checked=$$v},expression:"checked"}})],1),_c('div',{staticClass:"UserItem-title"},[_c('div',{staticClass:"UserItem-title-info"},[_c('a',{staticClass:"d-inline-block align-middle code pointer mr-2",on:{"click":_vm.toggleCollapse}},[_vm._v(_vm._s(_vm.document.id))]),(_vm.localStrategyUsername)?_c('span',{staticClass:"d-inline-block align-middle code ml-2 mr-2",attrs:{"data-cy":("local-strategy-username-" + _vm.localStrategyUsername)}},[_c('i',{staticClass:"fas fa-user text-secondary",attrs:{"title":"Username (local strategy)"}}),_vm._v(" "+_vm._s(_vm.localStrategyUsername)+" ")]):_vm._e(),(
            _vm.document.additionalAttribute && _vm.document.additionalAttribute.value
          )?_c('label',{staticClass:"UserItem-title-additionalAttribute",on:{"click":_vm.toggleCollapse}},[_vm._v("("+_vm._s(_vm.document.additionalAttribute.name)+": "+_vm._s(_vm.document.additionalAttribute.value)+")")]):_vm._e()]),_c('div',{staticClass:"UserItem-title-profiles"},_vm._l((_vm.profileList),function(profile){return _c('b-badge',{key:profile,staticClass:"mr-1 mt-1 mb-1",attrs:{"variant":"primary"}},[_c('router-link',{staticClass:"truncate text-white",attrs:{"to":{
              name: 'SecurityProfilesUpdate',
              params: { id: profile }
            }}},[_vm._v(_vm._s(profile))])],1)}),1)]),_c('div',{staticClass:"UserItem-actions"},[_c('b-button',{staticClass:"UserListItem-update",attrs:{"href":"","variant":"link","data-cy":("UserListItem-update--" + (_vm.document.id)),"disabled":!_vm.canEditUser,"title":_vm.canEditUser ? 'Edit User' : 'You are not allowed to edit this user'},on:{"click":function($event){$event.preventDefault();return _vm.update($event)}}},[_c('i',{staticClass:"fa fa-pencil-alt",class:{ disabled: !_vm.canEditUser }})]),_c('b-button',{staticClass:"UserListItem-delete",attrs:{"href":"","variant":"link","data-cy":("UserListItem-delete--" + (_vm.document.id)),"disabled":!_vm.canDeleteUser,"title":_vm.canDeleteUser
            ? 'Delete user'
            : 'You are not allowed to delete this user'},on:{"click":function($event){$event.preventDefault();return _vm.deleteDocument(_vm.document.id)}}},[_c('i',{staticClass:"fa fa-trash",class:{ disabled: !_vm.canDeleteUser }})])],1)]),_c('b-collapse',{staticClass:"ml-3 DocumentListItem-content",attrs:{"id":("collapse-" + (_vm.document.id))},model:{value:(_vm.expanded),callback:function ($$v) {_vm.expanded=$$v},expression:"expanded"}},[_c('pre',{directives:[{name:"json-formatter",rawName:"v-json-formatter",value:({ content: _vm.document, open: true }),expression:"{ content: document, open: true }"}]})])],1)}
var staticRenderFns = []

export { render, staticRenderFns }